
<!-- 子假条列表 -->
<template>
    <div class="excuse-list">
        <div class="page-main-container">
            <div class="filter-wrap">
                <expand-filter
                    :formData="formData"
                    margin-bottom="0"
                    margin-top="0"
                    @clickBtn="handlerFilterBtnClick"
                    @changeSel="handleChangeSel"
                ></expand-filter>
                <div class="button-line"  v-if="revokeAllBtnShow" ></div>
                <div>
                    <el-button
                        v-if="revokeAllBtnShow" 
                        type="warning" 
                        @click="handleRevokeAll"
                        :style="{backgroundColor:'#f5b942'}"
                    >撤销全部</el-button>
                </div>
            </div>

            <table-data
                id="table"
                ref="table"
                v-loading="loadingTable"
                :config="tableConfig"
                :tableData="tableData">
                <template #repairCode="{data}">
                        <div
                            v-if="detailState"
                            style="color: #3c7fff; cursor: pointer"
                            @click="formDetail(data)"
                            class="ellipsis"
                        >
                        <span v-if="data.manualCancelLeaveRemark && data.manualCancelLeaveRemark == '家长申请给学生销假' && data.approveStats != '6' && data.approveStats != '2'">【家长申请销假】</span>
                            <span v-if="data.approveStats == 1 && data.oldId && data.isRevoke != 1">【修改】</span>
                            <span v-if="data.approveStats == 1 && data.isRevoke == 1">【撤销】</span>
                            <span>{{ data.dataTitle }}</span>
                        </div>
                        <span v-else>
                            <span v-if="data.manualCancelLeaveRemark && data.manualCancelLeaveRemark == '家长申请给学生销假' && data.approveStats != '6' && data.approveStats != '2'">【家长申请销假】</span>
                            <span v-if="data.approveStats == 1 && data.oldId ">【修改】</span>
                            <span v-if="data.approveStats == 1 && data.isRevoke == 1">【撤销】</span>
                            <span>{{ data.dataTitle }}</span>
                        </span>
                    </template>
                <template v-slot:operation="slotData">
                    <el-button  v-if="slotData.data.showRevoke" type="text" @click="handleRevoke(slotData.data)">撤销</el-button>
                    <div v-else> - </div>
                </template>
            </table-data>
        </div>

    </div>
</template>

<script>
import { DialogWrapper } from 'common-local';
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter"
import TableData from "@/components/scrollWrapper/Sub/TableData"
import onResize from "@/mixins/onResize";
import { CampusOAModel } from "@/models/CampusOA";
import RecordsList from '@/libs/pageListOptimal.js';
import {
    mapMutations,
    mapState
} from 'vuex';

export default {
    name: 'ExcuseList',
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        DialogWrapper
    },
    props: {
        subExcuseData: {
            type: Object,
            default: (() => {})
        },
        formInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            campusOAModel: null,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择流程状态",
                        key: "processStatus",
                        list: [
                            {
                                label: "审批通过",
                                value: "2"
                            },
                            {
                                label: "审批通过（撤销拒绝）",
                                value: "5"
                            },
                            {
                                label: "审批中",
                                value: "1"
                            },
                            {
                                label: "审批拒绝",
                                value: "6"
                            },
                            {
                                label: "已撤销",
                                value: "4"
                            },
                        ]
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                    },
                ],
            },
            listQuery: {
                processStatus: ""
            },
            loadingTable: false,
            // 表格配置
            tableConfig: {
                thead: [
                    {
                        label: "表单标题",
                        // prop: "dataTitle",
                        type: "slot",
                        slotName: "repairCode",
                        fixed: false,
                        labelWidth: "360px",
                        tooltip: true,
                        align: 'center'
                    },
                    {
                        label: "摘要",
                        prop: "dataAbstract",
                        labelWidth: "400",
                        type: "tooltipColumnSpecial",
                        splitSign: "、",
                        signReplace: "；",
                        align: 'center'
                    },
                    {
                        label: "发起人",
                        prop: "applyName",
                        align: 'center',
                    },
                    {
                        label: "请假开始时间",
                        prop: "createTime",
                        labelWidth:'180px',
                        align: 'center',
                        type: "function",
                        callBack: (row) => {
                            if(row.applyContent){
                                return row.applyContent.leaveStartTime.value.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "请假结束时间",
                        prop: "endTime",
                        labelWidth:'180px',
                        align: 'center',
                        type: "function",
                        callBack: (row) => {
                            if(row.applyContent){
                                return row.applyContent.leaveEndTime.value.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "流程状态",
                        prop: "approveStats",
                        type: 'function',
                        align: 'left',
                        labelWidth: '120px',
                        callBack(row) {
                            let name = row.currentPersonName ?? ''
                            if (row.approveStats === '0') {
                                return `<div class="ellipsis flexBox" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#3C7FFF;margin-right:5px;"></div>
                                            <div>${name}未开始</div>
                                        </div>`
                            } else if (row.approveStats === '1') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style=" width:6px;height:6px;border-radius:50%;background:#3C7FFF;margin-right:5px;"></div>
                                            <div class="ellipsis" style="flex:1;">${name}审批中</div>
                                        </div>`
                            } else if (row.approveStats === '2') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#46cc95;margin-right:5px;"></div>
                                            <div>审批通过</div>
                                        </div>`
                            } else if (row.approveStats === '3') {
                                return  `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#f56c6c;margin-right:5px;"></div>
                                            <div>审批拒绝</div>
                                        </div>`
                            } else if (row.approveStats === '4') {
                                return `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#f5b942;margin-right:5px;"></div>
                                            <div>已撤销</div>
                                        </div>`
                            } else if (row.approveStats === '5') {
                                return  `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#46cc95;margin-right:5px;"></div>
                                            <div>审批通过(撤销拒绝)</div>
                                        </div>`
                            } else if (row.approveStats === '6') {
                                return  `<div class="ellipsis" style="display:flex;align-items:center;">
                                            <div style="width:6px;height:6px;border-radius:50%;background:#f56c6c;margin-right:5px;"></div>
                                            <div>审批拒绝</div>
                                        </div>`
                            } 
                        }
                    },
                    {
                        label: "假条状态",
                        prop: "excuseState",
                        type: 'function',
                        align: 'left',
                        labelWidth: '120px',
                        callBack(row) {
                            if (['1','2'].includes(row.excuseTempState)) {//如果是临时状态
                                if(row.excuseTempState == '1'){
                                    return `<div class="ellipsis" style="display:flex;align-items:center;">
                                                <div style=" width:6px;height:6px;border-radius:50%;background:#9D9D9D;margin-right:5px;"></div>
                                                <div class="ellipsis" style="flex:1;">撤销中</div>
                                            </div>`
                                } else if (row.excuseTempState == '2') {
                                    return `<div class="ellipsis" style="display:flex;align-items:center;">
                                                <div style=" width:6px;height:6px;border-radius:50%;background:#9D9D9D;margin-right:5px;"></div>
                                                <div class="ellipsis" style="flex:1;">修改中</div>
                                            </div>`
                                }
                            } else {
                                if (row.excuseState == '1') {
                                    return `<div class="ellipsis" style="display:flex;align-items:center;">
                                                <div style=" width:6px;height:6px;border-radius:50%;background:#3C7FFF;margin-right:5px;"></div>
                                                <div class="ellipsis" style="flex:1;">待出校</div>
                                            </div>`
                                } else if (row.excuseState == '2') {
                                    return `<div class="ellipsis" style="display:flex;align-items:center;">
                                                <div style=" width:6px;height:6px;border-radius:50%;background:#46CC95;margin-right:5px;"></div>
                                                <div class="ellipsis" style="flex:1;">待入校</div>
                                            </div>`
                                } else if (row.excuseState == '3') {
                                    return `<div class="ellipsis" style="display:flex;align-items:center;">
                                                <div style=" width:6px;height:6px;border-radius:50%;background:#9D9D9D;margin-right:5px;"></div>
                                                <div class="ellipsis" style="flex:1;">已结束</div>
                                            </div>`
                                } else if (row.excuseState == '4') {
                                    return `<div class="ellipsis" style="display:flex;align-items:center;">
                                                <div style=" width:6px;height:6px;border-radius:50%;background:#f5b942;margin-right:5px;"></div>
                                                <div class="ellipsis" style="flex:1;">已销假</div>
                                            </div>`
                                } 
                            }
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        align: 'center',
                        fixed: 'right',
                        slotName: 'operation',
                        labelWidth: '120',
                        className: 'text-spacing-reduction'
                    }
                ],
                rowkey: 'id',
                check: false,
                height: '',
            },
            
            detailState: true

        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        }),
        tableData(){
            let tableList = [];
            let list = this.subExcuseData.list;
            let processVal = this.listQuery.processStatus;
            if(list.length > 0){
                if(!processVal){
                    tableList = list;
                }else{
                    tableList = list.filter(item => item.approveStats == processVal)
                }

                return tableList;
            } else {
                return []
            }
        },
        revokeAllBtnShow(){
            if(this.subExcuseData && this.subExcuseData.isShow){
                return true
            } else {
                return false
            }
        },
        revokeAllBtnText(){
            if(this.subExcuseData && this.subExcuseData.isShow){
                return '撤销全部'
            } else {
                return ''
            }
        },
    },
    watch: {},
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        ...mapMutations(['setOAQueryData']),

        // 初始化入口
        init() {

        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
                // this.initData()
            }
        },
        // 获取列表
        getList(t) {
            this.$emit('getSubExcuseList')
        },


        /**
         * 页面按钮点击事件
         * handlerFilterBtnClick 筛选组件按钮点击事件
         */
        // 筛选组件按钮点击事件
        handlerFilterBtnClick(ev) {
            switch (ev.item.fn) {
                case 'primary':
                    for (let item of this.formData.data) {
                        this.listQuery[item.key] = item.value
                    }
                    this.getList()
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 撤销全部
         * @DoWhat: 撤销全部
         * @UseScenarios: 撤销全部
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-04-15 09:15:21
         */        
        handleRevokeAll(){
            const campusOA = new CampusOAModel()
            this.$prompt('请输入撤回理由', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^.+$/,
                inputErrorMessage: '撤回理由不能为空'
            }).then(({value}) => {
                campusOA.schoolProcessRevokeAll({
                    id: this.formInfo.schoolProcessApplyInfo.id,
                    dataTitle: this.formInfo.schoolProcessApplyInfo.dataTitle,
                    revokeReason: value,
                }).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success('撤销成功')
                        this.$emit('handleAfterRevokeAll')
                    })
                })
            }).catch(() => {
            });
        },
        formDetail(data) {
            this.$emit('handleGoSubExcuseDetail', data)
        },
        handleChangeSel(data){
            
        },
        //校验假条状态
        checkLeaveStatus(key,data) {
            let id = data.id
            let dataTitle = data.dataTitle
            return new Promise(resolve => {
                const campusOA = new CampusOAModel();
                campusOA.getProcessApplyInfo({
                    id: id,
                    dataTitle: dataTitle
                }).then((res) => {
                    this.handleRes(res, () => {
                        if(res.data.data[key] == true){
                            resolve(true)
                        }else{
                            this.$message.error('假条已结束，不可被撤销或修改。');
                            setTimeout(() => {
                                this.getList();
                            }, 1000)
                            resolve(false)
                        }
                    })
                }).catch(err => {
                    resolve(false)
                })
            })
        },
        // 撤销
        async handleRevoke(row) {
            let leaveStatus = await this.checkLeaveStatus('isRevoke', row);
            if (!leaveStatus) {
                return
            }
            const campusOA = new CampusOAModel()
            this.$prompt('请输入撤回理由', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^.+$/,
                inputErrorMessage: '撤回理由不能为空'
            }).then(({value}) => {
                campusOA.schoolProcessRevoke({
                    id: row.id,
                    dataTitle: row.dataTitle,
                    revokeReason: value,
                }).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success('撤销成功')
                        this.getList()
                    })
                })
            }).catch(() => {
            });
        },
        





    }
}
</script>

<style lang="scss" scoped>
.excuse-list {
    margin: 10px 10px 10px 0;
}

.filter-wrap {
    display: flex;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    border-radius: 4px;
    overflow: hidden;
    

    .expand-filter {
        padding: 0;
    }
}
</style>
